import React from "react"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react';
import {TOCR} from "./tocr"
import MDXComponrnts from "./MDXComponents"

import Writer from './writer'
import { Styles } from './article.css'

export default ({data}) => {
  return (
    <div className={Styles}>
        <div className="main">
            <TOCR
                headings={data.mdx.tableOfContents.items[0].items}
                position='sticky'
                top='0'
                d={['none', 'none', 'block', 'block']}
            />
            <div className="contents">
            <MDXProvider components={MDXComponrnts}>
            <MDXRenderer 
            frontmatter={data.mdx.frontmatter}
            localImages={data.mdx.frontmatter.embeddedImagesLocal}
            >
                {data.mdx.body}
            </MDXRenderer>
            </MDXProvider>
            {/* <Writer data={data}></Writer> */}
            </div>
        </div>
    </div>
  )
}
