import * as React from 'react'
import Layout from '../../components/layout'
import { graphql } from 'gatsby'

import Article from '../../components/article'

import SEO from "../../components/seo"






const BlogPost = ({ data }) => {
  return (
    <Layout>
      <SEO 
      pagetitle={data.mdx.tableOfContents.items[0].title}
      pagedesc={data.mdx.frontmatter.summarytxt}
      pagepath={`/${data.mdx.slug}`}
      pageimg={data.mdx.frontmatter.hero_image.childImageSharp.original.src}
      />
      <Article data={data}></Article>
    </Layout>
  )
}


export const query = graphql`
  query($slug: String) {
    mdx(slug: {eq: $slug}) {
      slug
      body
      tableOfContents
      frontmatter {
        summarytxt
        date(formatString: "MMMM DD, YYYY")
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_image {
          childImageSharp {
            gatsbyImageData(width: 700)
            original{
              src
              height
              width
            }
          }
        }
      }
    }
  }
`

export default BlogPost
