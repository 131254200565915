import * as React from 'react'
import { GatsbyImage, getImage, StaticImage  } from 'gatsby-plugin-image'

const MyH2 = props => <h2 id={props.children} style={{position:"relative"}} {...props}><a href={`#${props.children}`}></a>{props.children}</h2>
const MyH3 = props => <h3 id={props.children} style={{position:"relative"}} {...props}><a href={`#${props.children}`}></a>{props.children}</h3>
const talk = props => <div class="Talk__Wrapper-sc-9rqrpd-0 efiiSI"><div order="" transform="" class="Talk__TalkIcon-sc-9rqrpd-1 kbsNXM"><StaticImage class="single-author-image" src={`../../images/default-user.png`} width={75} height={75} alt=""/><span>{props.name}</span></div><div class="Talk__TalkContent-sc-9rqrpd-2 lkHHZh">{props.children}</div></div>
const talk_mai_normal = props => <div class="Talk__Wrapper-sc-9rqrpd-0 efiiSI"><div order="" transform="" class="Talk__TalkIcon-sc-9rqrpd-1 kbsNXM"><StaticImage class="single-author-image" src={`../../images/mai1.png`} width={75} height={75} alt=""/><span>{props.name}</span></div><div class="Talk__TalkContent-sc-9rqrpd-2 lkHHZh">{props.children}</div></div>


const MDXComponents = {
  h2: MyH2,
  h3: MyH3,
  Talk: talk,
  TalkMN: talk_mai_normal
};

export default MDXComponents;

<div class="Talk__TalkContent-sc-9rqrpd-2 lkHHZh">デフォルトです。</div>