import React from "react"



const HeadingList = ({ headings, itemMy = '4', ...props }) => (
    <div>
    {headings.map(heading => (
      <React.Fragment key={heading.title}>
        <li data-testid={heading.url} className="css-147dsqm"><a href={heading.url} className="css-1kvbwpa">{heading.title}</a></li>

        {heading.items && (
          <ul className="css-1fspo41" {...props}>
          <HeadingList
            headings={heading.items}
            itemMy='2'
          />
          </ul>
        )}
      </React.Fragment>
    ))}
    </div>
);

const TOCR = ({ headings, ...props }) => (
  <div className="css-1otrxbn"><nav className="css-1h992as" {...props}>

    <h2 className="css-nx7ts9">Table of Contents</h2>
    <ul className="css-1ig99e7">
      <HeadingList headings={headings} />
    </ul>
  </nav></div>
);

export { TOCR};
